/* styles.css */
.app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

nav {
  width: 100%;
  background-color: #333;
  color: white;
  padding: 10px 0;
}

nav ul {
  display: flex;
  justify-content: space-around;
  list-style-type: none;
  padding: 0;
}

nav ul li {
  cursor: pointer;
}

.page {
  margin-top: 20px;
  text-align: center;
}
